@use 'mixins/theme-builder.mixin';
@use 'functions/theme-builder.function' as theme-builder-fn;

$font:
  'Helvetica Neue LT W04',
  Helvetica Neue,
  Helvetica,
  Arial,
  sans-serif;
$heimstaden-palette: theme-builder-fn.create-flat-palette(#ff5000, white);
$heimstaden-warn-palette: theme-builder-fn.create-flat-palette(#c4401a, white);
$typography: theme-builder-fn.create-typography($font, 300, 400);
$heimstaden-theme: theme-builder-fn.create-theme(false, $heimstaden-palette, $heimstaden-warn-palette, $typography);

.material-theme-heimstaden {
  --primary-color: #ff5000;
  --primary-text-color: black;
  --primary-hover-color: #ff621a;
  --primary-hover-text-color: white;

  --primary-button-border-color: #ff5000;
  --primary-button-border-hover-color: #ff5000;

  --button-standard-background-color: white;
  --button-standard-text-color: #ff5000;
  --button-standard-border-color: #ff5000;

  --button-standard-hover-background-color: #f9f0ec;
  --button-standard-hover-text-color: #ff5000;
  --button-standard-hover-border-color: #ff5000;

  --button-border-radius: 24px;
  --button-horizontal-padding: 24px; // medium-plus

  --font-standard-color: #333333;
  --font-secondary-text-color: #67696a;
  --font-link-color: #ff5000;
  --font-link-weight: initial;
  --font-link-hover-color: #b33800;
  --font-standard-weight: 300;
  --link-text-decoration: none;

  --accent-color: #ff5000;
  --accent-text-color: white;

  --advert-background: #ff5000;
  --advert-text: white;

  --slide-accent-color: #ff5000;

  --font-family: Helvetica Neue LT W04, Helvetica Neue, Helvetica, Arial, sans-serif;

  --icon-font-color: #333333;
  --header-icon-color: #333333;

  --text-color-on-primary-background: white;

  @include theme-builder.override-theme-colors($heimstaden-theme);

  @include theme-builder.apply-typography($typography);
}
